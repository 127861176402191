/* RESPONSIBLE TEAM: team-data-foundations */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/no-observers */
/* eslint-disable ember/no-new-mixins */
import { observer } from '@ember/object';
import Mixin from '@ember/object/mixin';
import { inject as service } from '@ember/service';

let SegmentQueryParams = Mixin.create({
  permissionsService: service(),
  queryParams: ['permission'],
  permission: '',

  permissionErrorMessage: observer({
    dependentKeys: ['permission'],

    fn() {
      if (this.permission !== '') {
        this.permissionsService.checkPermission(this.permission).then(function () {
          return true;
        });
      }
    },

    sync: false,
  }),
});

export default SegmentQueryParams;
