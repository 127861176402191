/* RESPONSIBLE TEAM: team-data-foundations */
import UserConversationsFeedControllerBase from 'embercom/controllers/apps/app/users/user/base/user-conversations-feed-controller-base';
import { inject as service } from '@ember/service';

export default UserConversationsFeedControllerBase.extend({
  intl: service(),

  get emptyState() {
    return this.intl.t('inbox.conversation-details-sidebar.all-conversations.no-communication');
  },

  queryParams: ['external_step'],
  external_step: null,
});
