/* RESPONSIBLE TEAM: team-data-foundations */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-controllers */
/* eslint-disable ember/no-classic-classes */
import { action, computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { reads, alias, filterBy, mapBy, and } from '@ember/object/computed';
import Controller, { inject as controller } from '@ember/controller';
import { isValidEmail } from '@intercom/pulse/lib/computed-properties';
import { getDomainName, getUserName } from 'embercom/lib/email';
import { getAdminProfile } from 'embercom/lib/admins/admin-profiles';

export default Controller.extend({
  intl: service(),
  intercomEventService: service(),
  permissionsService: service(),
  store: service(),
  session: service(),
  modelDataCacheService: service(),
  appController: controller('apps/app'),
  isAdmin: true,
  socialAccountService: service('social-account-service'),
  commonPrimaryNavBarService: service('commonPrimaryNavBarService'),
  accountHeaderText: computed('intl.locale', 'model.is_me', function () {
    return this.get('model.is_me')
      ? this.intl.t('admins.admin.you')
      : this.intl.t('admins.admin.teammate');
  }),
  app: reads('appController.model'),
  notificationsService: service('notificationsService'),
  currentAdmin: alias('app.currentAdmin'),

  createdAtFromNow: computed('intl.locale', 'model.created_at', function () {
    return this.intl.formatRelative(this.get('model.created_at'));
  }),
  followedUserObjects: filterBy('model.follows', 'followable_type', 'user'),
  followedUserIds: mapBy('followedUserObjects', 'followable_id'),
  stampText: computed('intl.locale', 'model.is_me', function () {
    return this.get('model.is_me')
      ? this.intl.t('admins.admin.you')
      : this.intl.t('admins.admin.teammate');
  }),
  lastActive: computed('model.last_active', function () {
    return this.get('model.last_active') * 1000;
  }),
  email: alias('model.email'),
  emailUserName: computed('model.email', function () {
    return getUserName(this.get('model.email'));
  }),
  emailDomainForLink: computed('emailDomainName', function () {
    return `http://${this.emailDomainName}`;
  }),
  linkToEmailDomain: and('isValidEmail', 'isNotEmailDomain'),
  emailDomainName: computed('model.email', function () {
    return getDomainName(this.get('model.email'));
  }),
  isNotEmailDomain: computed('model.email', function () {
    let emailDomain = this.emailDomainName;
    return !['gmail.com', 'yahoo.com', 'hotmail.com', 'outlook.com'].includes(emailDomain);
  }),
  hasUnsavedChanges() {
    // this doesn't really achieve much now, because all the edits are stored in temporary
    // properties by this component: app/components/admin/about-component.js
    return this.get('model.hasDirtyAttributes');
  },
  isValidEmail: isValidEmail('model.email'),
  updateAttributes(editedAttributes) {
    this.model.setProperties(editedAttributes);
    let profile = this.get('model.profile');
    profile.set('job_title', editedAttributes.job_title);
    profile.set('department', editedAttributes.profile_department);
    profile.set('phone_number', editedAttributes.phone_number);
    profile.set('biography', editedAttributes.biography);

    this.model
      .save()
      .then(() => {
        return getAdminProfile(this.store, this.get('app.id'), this.get('model.id')).then(
          (profile) => {
            this.model.set('profile', profile);
            this.notifyConfirmation(
              this.intl.t('admins.admin.changes-saved'),
              'profileSaveSuccess',
            );
          },
        );
      })
      .then(() => {
        this.session.getTeammate(this.app.id);
        this.app.updateLocalCache(this.modelDataCacheService);
        this.currentAdmin.updateLocalCache(this.modelDataCacheService);
      })
      .catch((e) => {
        this.notifyErrors(e.jqXHR.responseJSON.errors, 'profileSaveErrors');
      });
  },
  notifyErrors(message, key) {
    this.notificationsService.notifyWarning(message, 60000, key);
  },
  notifyConfirmation(message, key) {
    this.notificationsService.notifyConfirmation(message, 3000, key);
  },
  openTeamSettingsPage: action(function () {
    this.permissionsService.checkPermission('can_access_workspace_settings').then(() => {
      this.transitionToRoute('apps.app.settings.helpdesk.teams');
    });
  }),
  handleWarnOnNavigation: action(function (shoudlWarnOnNavigation) {
    this.send('handleWarnOnNavigation', shoudlWarnOnNavigation);
  }),

  actions: {
    saveProfileInfo(editedAttributes) {
      this.updateAttributes(editedAttributes);
    },
    oAuthSuccess(provider) {
      this.socialAccountService.oAuthSuccess(this.model, provider).then((socialAccounts) => {
        this.intercomEventService.trackEvent(`teammate-social-profile-added-${provider}`);
        this.set('socialAccounts', socialAccounts);
      });
    },
    oAuthFailure(provider) {
      this.socialAccountService.oAuthFailure(provider);
    },
    deleteSocialAccount(model, provider) {
      this.socialAccountService.deleteSocialAccount(model, this.model, provider).then(() => {
        this.set(`${provider}Account`, null);
        this.intercomEventService.trackEvent(`teammate-social-profile-removed-${provider}`);
      });
    },
  },
});
