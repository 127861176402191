/* RESPONSIBLE TEAM: team-data-foundations */
import Controller from '@ember/controller';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class PeopleController extends Controller {
  queryParams = ['tab'];

  @tracked declare tab: string;

  get selectedTab() {
    return this.tab;
  }

  @action
  changeTab(tab: string) {
    this.set('tab', tab);
    this.tab = tab;
  }
}
