/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-controllers */
/* eslint-disable ember/no-classic-classes */
import Controller, { inject as controller } from '@ember/controller';
import { readOnly, gt } from '@ember/object/computed';
import { inject as service } from '@ember/service';

export default Controller.extend({
  router: service(),
  commonPrimaryNavBarService: service(),
  appController: controller('apps/app'),
  app: readOnly('appController.model'),
  totalCount: 0,
  showViews: gt('totalCount', 0),
  showSearch: false,
  actions: {
    toggleSearch() {
      this.toggleProperty('showSearch');
      if (this.showSearch) {
        this.transitionToRoute('apps.app.outbound.search');
      } else {
        this.transitionToRoute('apps.app.outbound.all', { queryParams: {} });
      }
    },
  },
});
