/* RESPONSIBLE TEAM: team-data-foundations */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-new-mixins */
import { debounce } from '@ember/runloop';
import Mixin from '@ember/object/mixin';
import ENV from 'embercom/config/environment';
import { inject as service } from '@ember/service';

export default Mixin.create({
  intercomEventService: service(),
  trackEditEvent() {
    this.trackEvent('edited');
  },
  trackEvent(action) {
    this.intercomEventService.trackAnalyticsEvent({
      action,
      object: 'filter',
      place: this.place,
      predicates: this.get('model.predicates'),
      number_filters: this.get('model.editablePredicatesWithoutRole.length'),
    });
  },
  processEvent(action) {
    if (action === 'edited') {
      debounce(this, this.trackEditEvent, ENV.APP._1000MS);
    } else {
      this.trackEvent(action);
    }
  },
});
