/* RESPONSIBLE TEAM: team-channels */
import Controller from '@ember/controller';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import type DomainService from 'embercom/services/domain-service';

export default class SocialChannelsController extends Controller {
  queryParams = ['tab'];

  @service declare domainService: DomainService;

  @tracked declare tab: string;

  get selectedTab() {
    return this.tab;
  }

  @action
  changeTab(tab: string) {
    this.tab = tab;
  }
}
