/* RESPONSIBLE TEAM: team-data-foundations */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/no-controllers */
/* eslint-disable ember/use-brace-expansion */
/* eslint-disable ember/no-classic-classes */
import Controller, { inject as controller } from '@ember/controller';
import { inject as service } from '@ember/service';
import { computed, action } from '@ember/object';
import { alias, notEmpty, readOnly, reads } from '@ember/object/computed';
import { findBy, slice } from '@intercom/pulse/lib/computed-properties';
// eslint-disable-next-line @intercom/intercom/no-legacy-modal
import ModalActions from 'embercom/controllers/mixins/users-and-companies/modal-actions';
import DetectsIntercomradesDomain from 'embercom/lib/detects-intercomrades-domain';
import safeWindowOpen from 'embercom/lib/safe-window-open';
import LegacySelectionState from 'embercom/models/legacy-selection-state';
import { later } from '@ember/runloop';
import ENV from 'embercom/config/environment';

const COMPANY_CUTOFF = 10;

export default Controller.extend(ModalActions, DetectsIntercomradesDomain, {
  intl: service(),
  attributeService: service(),
  // eslint-disable-next-line @intercom/intercom/no-legacy-modal
  modalService: service(),
  commonPrimaryNavBarService: service(),
  applicationController: controller('application'),
  appController: controller('apps/app'),
  appsController: controller('apps'),
  currentAdmin: alias('appsController.model'),
  app: reads('appController.model'),
  qualificationAttributes: readOnly('app.qualification_attributes'),
  showQualificationAttributeList: notEmpty('qualificationAttributes'),
  userAttributes: readOnly('attributeService.allUserAttributes'),
  companyAttributes: readOnly('attributeService.allCompanyAttributes'),
  emailDisplayedAttribute: findBy('userAttributes', 'identifier', 'email'),
  email: alias('emailDisplayedAttribute.attribute'),
  showArchiveModal: false,
  displayedCompanies: slice('model.companies', 0, COMPANY_CUTOFF),
  otherCompanies: slice('model.companies', COMPANY_CUTOFF),
  hasOtherCompanies: notEmpty('otherCompanies'),

  conversationTypeOptions: computed('intl.locale', function () {
    return [
      {
        value: 'all',
        text: this.intl.t('users.profile.conversation-types.messages-and-conversations'),
      },
      {
        value: 'conversations',
        text: this.intl.t('users.profile.conversation-types.conversations-only'),
      },
    ];
  }),

  selectedOption: 'conversations',

  anonymous: alias('model.is_anonymous'),
  stampText: computed('intl.locale', 'anonymous', function () {
    return this.anonymous ? this.intl.t('users.profile.lead') : this.intl.t('users.profile.user');
  }),
  scrollParentSelector: '.profile',

  mapUsers: computed('geoipData.latitude', 'geoipData.longitude', function () {
    return [
      {
        avatar: { image_urls: { normal: 'images/map-user-icon.png' } },
        geoip_data: {
          latitude: this.get('geoipData.latitude'),
          longitude: this.get('geoipData.longitude'),
        },
      },
    ];
  }),

  displayedCompanyAttributes: reads('attributeService.companyAttributes'),

  lookupAdminByEmail() {
    let userEmail = this.get('model.email');
    safeWindowOpen(`https://intercomrades.intercom.com/admin/admins?admin_id=${userEmail}`);
  },

  selectionState: computed('model', function () {
    return LegacySelectionState.create({
      isSelectAllMode: false,
      selected: [this.model],
      unselected: [],
      predicates: [],
      count: 1,
      search: '',
    });
  }),

  handleWarnOnNavigation: action(function (shouldWarnOnNavigation) {
    this.send('warnOnNavigation', shouldWarnOnNavigation);
  }),

  actions: {
    delete() {
      this.set('showArchiveModal', true);
    },
    subscription() {
      let component = 'users/modals/subscription';
      // eslint-disable-next-line @intercom/intercom/no-legacy-modal
      this.modalService.openModal(component, this.model);
    },
    conversationTypeSelectionChanged(conversationTypeSelected) {
      if (conversationTypeSelected === 'conversations') {
        this.transitionToRoute('apps.app.users.user.conversations');
      } else {
        this.transitionToRoute('apps.app.users.user.all-conversations');
      }
    },
    blockUser() {
      // eslint-disable-next-line @intercom/intercom/no-legacy-modal
      this.modalService.openModal('users/modals/block-user', this.model);
    },
    lookupAdminEmail() {
      this.lookupAdminByEmail();
    },
    changeConversationType(value) {
      this.send('conversationTypeSelectionChanged', value);
    },
    conversationStarted() {
      // wait 1s so the backend catches up before we load the new conversation
      later(() => {
        this.send('refresh');
      }, ENV.APP._1000MS);
    },
  },
});
