/* RESPONSIBLE TEAM: team-data-foundations */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/require-snake-case-analytics */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/no-controllers */
/* eslint-disable ember/no-classic-classes */
import { inject as service } from '@ember/service';
import { reads, gt } from '@ember/object/computed';
import Controller, { inject as controller } from '@ember/controller';
import { fmtStyle } from '@intercom/pulse/lib/computed-properties';

export default Controller.extend({
  intercomEventService: service(),
  appController: controller('apps.app'),
  app: reads('appController.model'),
  // eslint-disable-next-line @intercom/intercom/no-legacy-modal
  modalService: service(),
  mixpanelController: controller('apps.app.import.mixpanel'),
  importing: gt('userCreateProgress', 0),
  userCreateProgress: 0,
  importComplete: false,
  hasNoCustomTag: true,
  usersOrLeads: 'users',
  shouldCombineCreatedOrUpdatedCounts: gt('peopleCreatedOrUpdated', 0),
  shouldShowImportSummary: false,
  realTimeEventService: service(),
  progressWidth: fmtStyle('width: %@%', 'userCreateProgress'),

  init() {
    this._super(...arguments);
    this.realTimeEventService.subscribeTopics(['mixpanel-import-user-create-progress']);
    this.realTimeEventService.on(
      'MixpanelImportUserCreateProgress',
      this,
      'onMixpanelImportUserCreateProgress',
    );
  },

  willDestroy() {
    this.realTimeEventService.unsubscribeTopics(['mixpanel-import-user-create-progress']);
    this.realTimeEventService.off(
      'MixpanelImportUserCreateProgress',
      this,
      'onMixpanelImportUserCreateProgress',
    );
    this._super(...arguments);
  },

  onMixpanelImportUserCreateProgress(data) {
    let percentCompleted = Math.ceil(data.percentComplete);
    this.set('userCreateProgress', percentCompleted);
    if (percentCompleted === 100) {
      this.model.reload().then(() => {
        if (this.get('model.importSummary')) {
          let mixpanelImport = this.model;
          this.set('peopleCreated', mixpanelImport.get('importSummary').users_created);
          this.set('peopleUpdated', mixpanelImport.get('importSummary').users_updated);
          this.set(
            'peopleCreatedOrUpdated',
            parseInt(mixpanelImport.get('importSummary').users_created_or_updated, 10),
          );
          this.set('importComplete', true);
          this.mixpanelController.set('currentStep', 5);
        }
      });
    }
  },

  actions: {
    toggleImportSummary() {
      this.toggleProperty('shouldShowImportSummary');
    },
    finish(shouldTransitionToSummary) {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'mailchimp-import-finished',
        object: 'mailchimp-import',
      });

      if (window.opener) {
        window.opener.importSuccessful = true;
        if (shouldTransitionToSummary) {
          window.opener.shouldTransitionToSummary = true;
        }
        window.close();
      }
    },
    openTaggingModal(component, model, options) {
      this.modalService.openModal(component, model, options);
    },
  },
});
