/* RESPONSIBLE TEAM: team-data-foundations */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-controllers */
/* eslint-disable ember/use-brace-expansion */
/* eslint-disable ember/no-classic-classes */
import { computed } from '@ember/object';
import { reads, alias, readOnly } from '@ember/object/computed';
import Controller, { inject as controller } from '@ember/controller';
import { inject as service } from '@ember/service';

export default Controller.extend({
  intl: service(),
  adminController: controller('apps/app/admins/admin'),
  appController: controller('apps/app'),
  app: reads('appController.model'),
  admin: alias('adminController.model'),

  get emptyState() {
    return this.intl.t('admins.admin.index.no-conversations');
  },
  conversations: readOnly('model'),
  conversationHeaderText: computed('intl.locale', 'admin.is_me', function () {
    return this.get('admin.is_me')
      ? this.intl.t('admins.admin.index.your-conversations')
      : this.intl.t('admins.admin.index.their-conversations');
  }),
  isPaginating: false,
  hasMoreConversationsToLoad: true,
  pendingUpdateConversationIds: [],

  scrollParentSelector: '.profile',
  emptyStateText: computed('intl.locale', 'admin.first_name', 'admin.is_me', function () {
    return this.get('admin.is_me')
      ? this.intl.t('admins.admin.index.no-conversations-assigned-to-you')
      : this.intl.t('admins.admin.index.no-conversations-assigned-to-name', {
          firstName: this.get('admin.first_name'),
        });
  }),
});
