/* RESPONSIBLE TEAM: team-channels */
import Controller from '@ember/controller';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import type DomainService from 'embercom/services/domain-service';

export default class EmailController extends Controller {
  queryParams = ['tab', 'highlight', 'step', 'email'];

  @service declare domainService: DomainService;

  @tracked declare tab: string;
  @tracked declare step: string | null;
  @tracked highlight: string | null = null;
  @tracked declare email: string | null;

  get selectedTab() {
    return this.tab;
  }

  @action
  changeTab(tab: string) {
    this.tab = tab;
  }

  @action
  changeStep(step: string | null) {
    this.step = step;
  }

  @action
  async setEmail(email: string | null) {
    this.email = email;
    if (email) {
      this.domainService.setSelectedDomainWithEmail(email);
      await this.domainService.load();
    }
  }
}
