/* RESPONSIBLE TEAM: team-data-foundations */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/require-snake-case-analytics */
/* eslint-disable ember/no-controllers */
/* eslint-disable ember/no-classic-classes */
import { reads } from '@ember/object/computed';
import Controller, { inject as controller } from '@ember/controller';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { action } from '@ember/object';

export default Controller.extend({
  intercomEventService: service(),
  notificationsService: service(),
  intl: service(),
  appController: controller('apps.app'),
  app: reads('appController.model'),
  // eslint-disable-next-line @intercom/intercom/no-legacy-modal
  modalService: service(),
  mailchimpController: controller('apps.app.import.mailchimp'),
  usersOrLeads: reads('model.recordType'),
  mailchimpList: null,
  completeImport: task(function* () {
    try {
      this.set('model.listId', this.get('mailchimpController.mailchimpList.id'));
      this.set('model.userCount', this.get('mailchimpList.stats.member_count'));
      let mailchimpImport = yield this.model.save();
      if (mailchimpImport) {
        this.intercomEventService.trackAnalyticsEvent({
          action: 'mailchimp-import-initiated',
          object: 'mailchimp-import',
        });
        this.transitionToRoute('apps.app.import.mailchimp.finish', mailchimpImport);
      }
    } catch (error) {
      this.notificationsService.notifyError(this.intl.t('apps.app.import.mailchimp.went-wrong'));
    }
  }),

  openTaggingModal: action(function (component, model, options) {
    this.modalService.openModal(component, model, options);
  }),
});
