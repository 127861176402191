/* RESPONSIBLE TEAM: team-actions */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-controllers */
/* eslint-disable ember/no-classic-classes */
import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { readOnly } from '@ember/object/computed';
import { task } from 'ember-concurrency';

export default Controller.extend({
  intercomEventService: service(),
  notificationsService: service(),
  developerHub: service(),
  appPackage: readOnly('developerHub.appPackage'),

  startingGuideConfig: null,
  addAnalyticsEvent() {
    this.intercomEventService.trackAnalyticsEvent({
      object: 'app_package',
      section: 'getting_started_guide',
      app_package_id: this.appPackage.id,
      app_metadata: this.appPackage.changeRequest,
      place: 'developer_hub',
      action: 'save',
    });
  },

  saveGettingStartedGuide: task(function* () {
    this.appPackage.changeRequest.appPackageConfig.startingGuide = this.startingGuideConfig;
    try {
      yield this.appPackage.save();
      this.addAnalyticsEvent();
      this.notificationsService.notifyConfirmation('Your changes were saved successfully');
    } catch (e) {
      this.notificationsService.notifyError('There was a problem applying your changes');
    }
  }),

  actions: {
    onUpdate(newStartingGuide) {
      this.set('startingGuideConfig', newStartingGuide);
    },
  },
});
