/* RESPONSIBLE TEAM: team-data-foundations */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-controllers */
import UserConversationsFeedControllerBase from 'embercom/controllers/apps/app/users/user/base/user-conversations-feed-controller-base';
import { inject as service } from '@ember/service';

export default UserConversationsFeedControllerBase.extend({
  intl: service(),

  get emptyState() {
    return this.intl.t('inbox.conversation-details-sidebar.conversation.no-conversation');
  },
});
