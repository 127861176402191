/* RESPONSIBLE TEAM: team-actions */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-controllers */
/* eslint-disable ember/no-classic-classes */
import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { alias, readOnly, and } from '@ember/object/computed';
import { task } from 'ember-concurrency';

export default Controller.extend({
  intercomEventService: service(),
  notificationsService: service(),
  developerHub: service(),
  intercomConfirmService: service(),
  appService: service(),
  isOnEdit: alias('developerHub.isOnEditPage'),
  appPackage: readOnly('developerHub.appPackage'),
  appPackageConfig: readOnly('appPackage.changeRequest.appPackageConfig'),
  canDisplayListingButton: and('appPackage.appStoreListing', 'appPackage.isPublicApp'),

  addAnalyticsEvent(action) {
    this.intercomEventService.trackAnalyticsEvent({
      object: 'app_package',
      app_package_id: this.get('developerHub.appPackage.id'),
      app_metadata: this.get('developerHub.appPackage.changeRequest'),
      action,
    });
  },

  isAppPackageConfigInvalid: task(function* () {
    let { validations } = yield this.appPackageConfig.validate();
    return validations.get('isInvalid');
  }),

  proceedToNextTask: task(function* () {
    try {
      let isInvalid = yield this.isAppPackageConfigInvalid.perform();
      if (isInvalid) {
        return;
      }

      yield this.appPackage.save();
      this.addAnalyticsEvent('proceed_to_getting_started_guide');
      this.transitionToRoute(
        'apps.app.developer-hub.app-packages.app-package.getting-started-guide',
      );
    } catch (e) {
      this.notificationsService.notifyError('There was a problem applying your changes.');
    }
  }),
  publishListingTask: task(function* () {
    let isConfirmed = yield this.intercomConfirmService.confirm({
      title: 'Publish to the App Store?',
      confirmButtonText: 'Publish to App Store',
      cancleButtonText: 'Cancel',
      body: 'Your app will be publicly listed in the Intercom App Store.',
    });
    if (!isConfirmed) {
      return;
    }
    try {
      yield this.appPackage.publishListing();
      this.addAnalyticsEvent('published');
      this.notificationsService.notifyConfirmation('Your listing has been published.');
    } catch (e) {
      this.notificationsService.notifyError('There was a problem applying your settings.');
    }
  }),
  cancelListingTask: task(function* () {
    let isConfirmed = yield this.intercomConfirmService.confirm({
      title: 'Remove from the App Store?',
      confirmButtonText: 'Remove listing',
      cancleButtonText: 'Cancel',
      body: 'Your app listing will be removed from the Intercom App Store.',
      primaryButtonType: 'primary-destructive',
    });
    if (!isConfirmed) {
      return;
    }
    try {
      yield this.appPackage.cancelListing();
      this.addAnalyticsEvent('unpublished');
      this.notificationsService.notifyConfirmation('Your listing has been unpublished.');
    } catch (e) {
      this.notificationsService.notifyError('There was a problem applying your settings.');
    }
  }),
  actions: {
    cancelEditAppPackage() {
      this.appPackage.rollbackAttributes();
      this.addAnalyticsEvent('cancelled');
      this.transitionToRoute('apps.app.developer-hub.app-packages.app-package.app-store-listing');
    },
  },
});
