/* RESPONSIBLE TEAM: team-data-foundations */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/require-snake-case-analytics */
/* eslint-disable ember/no-controllers */
/* eslint-disable ember/no-classic-classes */
import { reads } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import Controller, { inject as controller } from '@ember/controller';
import { action } from '@ember/object';
export default Controller.extend({
  intercomEventService: service(),
  appController: controller('apps/app'),
  app: reads('appController.model'),

  transitionToTag: action(function () {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'mailchimp-import-authenticated',
      object: 'mailchimp-import',
    });
    let mixpanelImport = this.model;

    this.transitionToRoute('apps.app.import.mixpanel.tag', mixpanelImport);
  }),
});
