/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */
import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import type { EditorMode } from 'embercom/components/knowledge-hub/content-editor/drawer-editor';
import { type ContentFilter } from 'embercom/components/knowledge-hub/overview';
import { DRAWER_EDITOR_QUERY_PARAMS } from 'embercom/services/knowledge-hub-drawer-editor-service';
import type KnowledgeHubService from 'embercom/services/knowledge-hub-service';
import { task } from 'ember-concurrency-decorators';

export default class KnowledgeOverviewController extends Controller {
  @service declare knowledgeHubService: KnowledgeHubService;
  queryParams = ['tab', 'manageConversations', 'manageMacros', ...DRAWER_EDITOR_QUERY_PARAMS];

  // Drawer Editor query params
  @tracked activeContentId?: number;
  @tracked activeContentType?: string;
  @tracked editorMode?: EditorMode;

  @tracked tab?: ContentFilter | undefined;
  @tracked manageConversations = false;
  @tracked manageMacros = false;

  @task({ restartable: true })
  *reloadKnowledgeUsageSummary() {
    yield this.knowledgeHubService.fetchKnowledgeUsageSummary();
  }
}
