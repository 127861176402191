/* RESPONSIBLE TEAM: team-data-foundations */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/use-brace-expansion */
/* eslint-disable ember/no-new-mixins */
import { computed } from '@ember/object';
import { not, and } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import Mixin from '@ember/object/mixin';
export default Mixin.create({
  bootService: service(),
  intercomEventService: service(),
  frontendStatsService: service(),
  trackSearchEvents() {
    if (this.get('segment.hasDirtyAttributes')) {
      this.trackFilterBarSearchEvent();
    }

    if (this.get('search.hasValue')) {
      this.trackSearchBoxSearchEvent();
    }
  },
  trackSearchBoxSearchEvent() {
    let searchMetaData = { search_value: this.get('search.text') };
    this.intercomEventService.trackEvent('applied-search-to-user-list', searchMetaData);
  },

  trackFilterBarSearchEvent() {
    let predicates = this.get('segment.isOrGroup')
      ? this.get('segment.nestedPredicates')
      : this.get('segment.predicates');

    let addFilterAttributeToHash = function (metadataHash, predicate, index) {
      let idx = index + 1;
      let attr_key = `filter_attribute_${idx}`;
      let comparator_key = `filter_comparator_${idx}`;
      metadataHash[attr_key] = predicate.attribute;
      metadataHash[comparator_key] = predicate.comparison;
      return metadataHash;
    };

    let searchMetaData = predicates
      .rejectBy('attribute', 'anonymous')
      .reduce(addFilterAttributeToHash, {});

    searchMetaData.isOrGroup = this.get('segment.isOrGroup');
    searchMetaData.has_nested_logical_predicates = this.get('segment.nestingLevelGreaterThanOne');
    this.intercomEventService.trackEvent('applied-filter-to-user-list', searchMetaData);
  },

  executeSearch(modelClass, responseKey, options) {
    if (this.isDestroying) {
      return;
    }
    this.setProperties({
      page: options.page,
      isPaginating: true,
      hasErrored: false,
      isLimitedResponse: false,
    });
    this.trackSearchEvents();

    if (!this.get('segment.hasDirtyAttributes')) {
      options.segmentId = this.get('segment.id');
    }

    return modelClass.search(this.app, this.predicates, options).then(
      (response) => {
        if (responseKey === 'users') {
          this.frontendStatsService.timeUntilAfterRender('ember_ux_userListRenderTime');
        }

        Em.beginPropertyChanges();
        if (options.page === 1) {
          this.set('model', []);
        }
        this.addReturnedRecordsToList(response[responseKey]);
        this.finishedLoading();
        Em.endPropertyChanges();
        if (response.totalCount !== undefined) {
          this.updateCountView(response.totalCount);
        }
        if (response.totalPages !== undefined && response.totalPages !== null) {
          this.set('totalPages', response.totalPages);
        }
        if (response.limited === true) {
          this.set('isLimitedResponse', true);
        }

        this.bootService.advanceToSecondaryPhase();
      },
      (error) => {
        if (error.errorThrown !== 'abort') {
          this.set('hasErrored', true);
          this.set('paginationErrorMessage', error.jqXHR.responseJSON.message);
        }
      },
    );
  },
  updateCountView(count) {
    this.set('totalCount', count);
    this.updateCachedCount(count);
    this.updateCachedAppCount(count);
    this.set('countSuccessful', true);
  },
  addReturnedRecordsToList(usersOrCompanies) {
    let model = this.model;
    let isSelectAllMode = this.isSelectAllMode;

    usersOrCompanies.forEach(function (user) {
      if (!model.findBy('id', user.get('id'))) {
        model.pushObject(user);
        if (isSelectAllMode) {
          model.set('lastObject.selected', true);
        }
      }
    });
  },
  updateCachedAppCount(count) {
    if (this.isCleanAllUsersSegmentAndNotSearch) {
      this.set('app.peopleCount', this.get('app.anonymousCount') + count);
    } else if (this.get('segment.isAllCompaniesSegmentWithoutChange')) {
      this.set('app.company_count', count);
    } else if (this.isCleanAllContactsSegmentAndNotSearch) {
      this.set('anonymousCount', count);
      this.set('app.anonymousCount', count);
    }
  },

  searchHasNoValue: not('search.hasValue'),
  isCleanAllUsersSegmentAndNotSearch: and(
    'searchHasNoValue',
    'segment.isAllUsersSegmentWithoutChange',
  ),
  isCleanAllContactsSegmentAndNotSearch: and(
    'searchHasNoValue',
    'segment.isAllUsersSegmentWithoutChange',
  ),

  countParameters: computed(
    'predicates',
    'app',
    'segment.hasDirtyAttributes',
    'segment.id',
    function () {
      let countParams = {
        app_id: this.app.get('id'),
        predicates: this.predicates,
      };
      if (!this.get('segment.hasDirtyAttributes')) {
        countParams.segment_id = this.get('segment.id');
      }
      return countParams;
    },
  ),
});
