/* RESPONSIBLE TEAM: team-data-foundations */
import type Store from '@ember-data/store';
import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { type ImportZendeskContext } from 'embercom/lib/apps/app/new-settings/data/imports-exports/import-zendesk/zendesk-context-loader';

export default class IndexController extends Controller {
  queryParams = ['tab'];

  declare model: {
    csv: any;
    mailchimp: any;
    mixpanel: any;
  };
  @service declare store: Store;

  @tracked hasMoreCsvImports = false;
  @tracked hasMoreMailchimpImports = false;
  @tracked hasMoreMixpanelImports = false;
  @tracked importZendesk: { enabled: boolean; context?: ImportZendeskContext } = {
    enabled: false,
    context: undefined,
  };
  @tracked declare tab: string;

  get selectedTab() {
    return this.tab;
  }

  @action
  changeTab(tab: string) {
    this.set('tab', tab);
    this.tab = tab;
  }

  get customModel() {
    return {
      csvImports: this.store.peekAll('csv-import'),
      mailchimpImports: this.store.peekAll('mailchimp-import'),
      mixpanelImports: this.store.peekAll('mixpanel-import'),
      hasMoreCsvImports: this.hasMoreCsvImports,
      hasMoreMailchimpImports: this.hasMoreMailchimpImports,
      hasMoreMixpanelImports: this.hasMoreMixpanelImports,
      importZendesk: this.importZendesk,
    };
  }
}
