/* RESPONSIBLE TEAM: team-data-foundations */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-controllers */
import Controller, { inject as controller } from '@ember/controller';
import { computed } from '@ember/object';
import { alias, filterBy, mapBy, notEmpty, readOnly, reads } from '@ember/object/computed';
// eslint-disable-next-line @intercom/intercom/no-legacy-modal
import ModalActions from 'embercom/controllers/mixins/users-and-companies/modal-actions';
import LegacySelectionState from 'embercom/models/legacy-selection-state';
import { inject as service } from '@ember/service';

const STANDARD_COMPANY_ATTRIBUTES = ['company.size', 'company.industry', 'company.website'];

export default Controller.extend(ModalActions, {
  app: reads('appController.model'),
  appController: controller('apps/app'),
  appsController: controller('apps'),
  admin: alias('appsController.model'),
  permissionsService: service(),
  attributeService: service(),
  // eslint-disable-next-line @intercom/intercom/no-legacy-modal
  modalService: service(),

  standardCompanyAttributes: computed('attributeService.allCompanyAttributes.[]', function () {
    return this.get('attributeService.allAttributes').filter((attribute) =>
      STANDARD_COMPANY_ATTRIBUTES.includes(attribute.get('identifier')),
    );
  }),

  displayableAttributes: computed(
    'attributeService.displayedCompanyAttributes.[]',
    'planHasValue',
    function () {
      let displayableAttributes = this.get('attributeService.displayedCompanyAttributes');
      if (typeof displayableAttributes !== 'undefined') {
        let attributesToDisplay = this.get('attributeService.displayedCompanyAttributes')
          .rejectBy('attribute.type', 'manual_tag')
          .rejectBy('attribute.type', 'company_tag')
          .rejectBy('attribute.name', 'admin_link')
          .rejectBy('attribute.type', 'plan')
          .filter(
            (attribute) => !STANDARD_COMPANY_ATTRIBUTES.includes(attribute.get('identifier')),
          );
        if (this.planHasValue) {
          attributesToDisplay = attributesToDisplay.rejectBy('attribute.name', 'Monthly Spend');
        }
        return attributesToDisplay.slice(0, 5);
      }
    },
  ),

  companyAttributes: readOnly('attributeService.allCompanyAttributes'),

  hiddenAttributes: computed('companyAttributes.[]', 'displayableAttributes.[]', function () {
    let displayableAttributes = this.displayableAttributes;
    return this.companyAttributes.filter(function (attribute) {
      return displayableAttributes.indexOf(attribute) < 0;
    });
  }),

  selectionState: computed('model', function () {
    return LegacySelectionState.create({
      isSelectAllMode: false,
      selected: [this.model],
      unselected: [],
      predicates: [],
      count: 1,
      search: '',
    });
  }),
  attributeModels: mapBy('attributeService.displayableAttributes', 'attribute'),
  planAttributeModels: filterBy('attributeModels', 'type', 'plan'),
  planAttribute: reads('planAttributeModels.firstObject'),
  planHasValue: notEmpty('model.plan.name'),
  spendAttributeModels: filterBy('attributeModels', 'name', 'Monthly Spend'),
  spendAttribute: reads('spendAttributeModels.firstObject'),

  actions: {
    delete() {
      let component = 'companies/company/modals/delete';
      let options = { redirect: 'apps.app.companies.segments' };
      this.modalService.openModal(component, this.selectionState, options);
    },
  },
});
