/* RESPONSIBLE TEAM: team-actions */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-controllers */
/* eslint-disable ember/no-classic-classes */
import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { alias } from '@ember/object/computed';

export default Controller.extend({
  notificationsService: service(),
  developerHub: service(),
  appPackage: alias('developerHub.appPackage'),
  updateApiVersion: task(function* (selectedApiVersion) {
    try {
      this.set('appPackage.apiVersion', selectedApiVersion);
      yield this.appPackage.updateApiVersion();
      this.send('refreshRoute');
      this.notificationsService.notifyConfirmation(
        'API Version for your app was successfully updated.',
      );
    } catch (error) {
      this.notificationsService.notifyError('There was a problem setting your API Version.');
      this.appPackage.rollbackAttributes();
    }
  }),
});
