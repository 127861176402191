/* RESPONSIBLE TEAM: team-workflows */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-controllers */
import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import RouteRegexes from 'embercom/lib/route-regexes';
import { assetUrl } from '@intercom/pulse/helpers/asset-url';

export default class AutomationController extends Controller {
  @service router;
  @service store;
  @service navbarCollapsingService;
  @service appService;
  @service intl;
  @service contentImportService;
  @service commonPrimaryNavBarService;

  get app() {
    return this.appService.app;
  }

  get isOnReportingRoute() {
    return RouteRegexes.operatorReporting.test(this.router.currentName);
  }
  get isOnOperatorSettingsRoute() {
    return RouteRegexes.operatorSettings.test(this.router.currentRouteName);
  }
  get isOnCustomBotRoute() {
    return RouteRegexes.customBots.test(this.router.currentRouteName);
  }
  get isOnTaskBotRoute() {
    return RouteRegexes.taskBots.test(this.router.currentRouteName);
  }
  get isOnWorkflowBasicsRoute() {
    return RouteRegexes.workflowBasics.test(this.router.currentRouteName);
  }
  get isOnSolveOnboardingRoute() {
    return RouteRegexes.solveOnboarding.test(this.router.currentRouteName);
  }
  get isOnSingleCustomBotRoute() {
    return RouteRegexes.singleCustomBot.test(this.router.currentRouteName);
  }
  get isOnCustomBotOverviewRoute() {
    return RouteRegexes.customBotOverview.test(this.router.currentRouteName);
  }
  get isOnFinAIAgentRoute() {
    return RouteRegexes.automation.finAIAgent.test(this.router.currentRouteName);
  }
  get isOnInsightsRoute() {
    return RouteRegexes.automation.insights.test(this.router.currentRouteName);
  }
  get showSingleFinNav() {
    return !this.app.hasConsentedToFinIngestion;
  }
  get isOnContentLibraryRoute() {
    return (
      RouteRegexes.externalContent.test(this.router.currentRouteName) ||
      RouteRegexes.finContent.test(this.router.currentRouteName)
    );
  }
  get showTaskBotSection() {
    return !this.app.canUseWorkflowsNetNewExperience;
  }
  get showBasicsSection() {
    return this.app.canUseWorkflowsNetNewExperience;
  }
  get numberOfPendingItemsToReview() {
    return this.contentImportService.aiContentReviewSummary.pending;
  }
  get canUseExternalContentLibrary() {
    return this.app.canUseExternalContentLibrary;
  }

  currentViewedCustomBotTarget = undefined;
  showSearch = true;

  initializeConfigurationModel() {
    let messengerResponseTimeKey =
      this.get('model.availability.messengerResponseTimeKey') || 'a_few_minutes';
    this.set(
      'previewConfiguration',
      this.store.createFragment('messenger-preview/configuration', {
        locale: 'en',
        backgroundPatternNumber: 1,
        logoURL: assetUrl('/assets/images/error/intercom-logo.png'),
        appPrimaryColor: this.get('model.lookAndFeel.primaryColor'),
        appSecondaryColor: this.get('model.lookAndFeel.secondaryColor'),
        greetingText: this.intl.t('controllers.apps.app.operator.hi-there'),
        aboutUsText: this.intl.t('controllers.apps.app.operator.happy-to-help'),
        messengerResponseTimeKey,
        showWeRunOnIntercom: true,
        inboundConversationsEnabledForUsers: false,
        inboundConversationsEnabledForVisitors: false,
        launcherVisibleForUsers: true,
        launcherVisibleForVisitors: true,
      }),
    );
  }

  @action
  toggleSearch() {
    this.toggleProperty('showSearch');
    if (!this.showSearch) {
      this._resetSearch();
    }
  }

  _resetSearch() {
    this.router.transitionTo('apps.app.automation.workflows-overview', {
      queryParams: {
        title: null,
      },
    });
  }
}
