/* RESPONSIBLE TEAM: team-proactive-support */
import OutboundBaseListController from 'embercom/lib/outbound/base-list-controller';
import { objectIcons, objectTypes } from 'embercom/models/data/matching-system/matching-constants';
import safeWindowOpen from 'embercom/lib/safe-window-open';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';

export default class SeriesIndexController extends OutboundBaseListController {
  @service intl;
  @service intercomEventService;
  @service appService;
  @tracked showContentCreationModal;
  @tracked selectedTemplate;
  @tracked showVideoModal;

  queryParams = ['product_tour_id'];
  wistiaId = 'vvl5ilinua';

  get bannerData() {
    return {
      pageTitle: this.intl.t('outbound.series.banner.page-title'),
      button: this.intl.t('outbound.series.banner.new-series'),
      icon: objectIcons[objectTypes.series],
      selectedObjectType: objectTypes.series,
      description: this.intl.t('outbound.series.banner.description'),
    };
  }

  get showFilters() {
    return this.hasAppliedFilter || this.hasItemsToDisplay;
  }

  get shouldShowEmptyStateBanner() {
    return !this.hasItemsToDisplay && !this.hasAppliedFilter;
  }

  @action
  selectTemplate(template) {
    this.selectedTemplate = template;
    this.showContentCreationModal = true;
  }

  @action
  onNewContentModalClose() {
    this.selectedTemplate = undefined;
    this.showContentCreationModal = false;
  }

  @action
  trackLearningOpened() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'learn_dropdown',
    });
  }

  @action
  learnHowSeriesWorks() {
    let link =
      'https://www.intercom.com/help/en/articles/4425207-orchestrate-your-customer-messaging-with-series';
    this._trackAnalytics(link, 'article');
    safeWindowOpen(link, '_blank');
  }

  @action
  showVideo() {
    this._trackAnalytics(this.wistiaId, 'video');
    this.showVideoModal = true;
  }

  @action
  showTour() {
    let tourId = '148431';
    this._trackAnalytics(tourId, 'tour');
    Intercom('startTour', tourId);
  }

  @action
  showAskCommunity() {
    let link =
      'https://forum.intercom.com/s/topic/0TO2G000000XdO7WAK/messages-series-smart-campaigns';
    this._trackAnalytics(link, 'developer');
    safeWindowOpen(link, '_blank');
  }

  @action
  createNewSeries({ templateId }) {
    this.transitionToRoute('apps.app.outbound.series.new', {
      queryParams: { mode: 'edit', template_id: templateId },
    });
  }

  _trackAnalytics(linked_to, content_type) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'help_content_link',
      place: 'series',
      linked_to,
      content_type,
    });
  }
}
