/* RESPONSIBLE TEAM: team-actions */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-jquery */
import $ from 'jquery';
import Controller from '@ember/controller';
import { inject as service } from '@ember/service';

export default Controller.extend({
  router: service(),
  appstoreService: service(),
  queryParams: [
    'app_package_code',
    'tab',
    'install_success',
    'error_message',
    'is_onboarding',
    'email_request',
    'redirect',
    { showReinstall: 'show_reinstall' },
  ],
  app_package_code: null,
  tab: null,
  install_success: null,
  error_message: null,
  is_onboarding: null,
  showReinstall: false,
  actions: {
    closeModal() {
      if ($.isEmptyObject(this.previousQueryParams)) {
        this.transitionToRoute(this.get('appstoreService.baseRoute'));
      } else {
        this.setProperties({
          app_package_code: null,
          tab: null,
          install_success: null,
          error_message: null,
          is_onboarding: null,
          redirect: null,
        });
        this.transitionToRoute(this.get('appstoreService.baseRoute'), {
          queryParams: this.previousQueryParams,
        });
      }
    },
    transitionAppPackageTab(tab) {
      this.transitionToRoute({
        queryParams: { tab: tab || null },
      });
    },
  },
});
