/* RESPONSIBLE TEAM: team-data-foundations */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/require-snake-case-analytics */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-controllers */
/* eslint-disable ember/no-observers */
/* eslint-disable ember/no-classic-classes */
import { observer } from '@ember/object';
import { inject as service } from '@ember/service';
import { reads, gt } from '@ember/object/computed';
import Controller, { inject as controller } from '@ember/controller';
import { fmtStyle } from '@intercom/pulse/lib/computed-properties';

export default Controller.extend({
  intercomEventService: service(),
  csvController: controller('apps.app.import.csv'),
  appController: controller('apps.app'),
  app: reads('appController.model'),
  // eslint-disable-next-line @intercom/intercom/no-legacy-modal
  modalService: service(),
  importing: gt('userCreateProgress', 0),
  importComplete: false,
  userCreateProgress: 0,
  usersOrLeads: reads('model.recordType'),
  hasNoCustomTag: true,
  userListUrl: null,
  shouldShowImportSummary: gt('model.failedRecords.length', 0),
  showLeadsMerged: gt('leadsMerged', 0),
  shouldCombineCreatedOrUpdatedCounts: gt('peopleCreatedOrUpdated', 0),
  realTimeEventService: service(),
  progressWidth: fmtStyle('width: %@%', 'userCreateProgress'),

  init() {
    this._super(...arguments);
    this.realTimeEventService.subscribeTopics(['csv-import-user-create-progress']);
    this.realTimeEventService.on(
      'CsvImportUserCreateProgress',
      this,
      'onCsvImportUserCreateProgress',
    );
  },

  willDestroy() {
    this.realTimeEventService.off(
      'CsvImportUserCreateProgress',
      this,
      'onCsvImportUserCreateProgress',
    );
    this.realTimeEventService.unsubscribeTopics(['csv-import-user-create-progress']);
    this._super(...arguments);
  },

  onCsvImportUserCreateProgress(data) {
    let csvImport = this.model;
    if (parseInt(csvImport.get('id'), 10) !== data.id) {
      return;
    }
    if (this.userCreateProgress > data.progress) {
      return;
    }
    this.set('taggableId', data.id);
    this.set('userCreateProgress', parseInt(data.progress, 10));

    if (data.complete) {
      this.model.reload();
    }
  },

  updateImportSummary: observer({
    dependentKeys: ['model.importSummary'],

    fn() {
      if (this.get('model.importSummary')) {
        let csvImport = this.model;
        let importType = this.get('model.recordType');
        this.set('leadsMerged', csvImport.get('importSummary').leads_merged);
        if (importType === 'users') {
          this.set('peopleCreated', csvImport.get('importSummary').users_created);
          this.set('peopleUpdated', csvImport.get('importSummary').users_updated);
          this.set(
            'peopleCreatedOrUpdated',
            parseInt(csvImport.get('importSummary').users_created_or_updated, 10),
          );
        } else {
          this.set('peopleCreated', csvImport.get('importSummary').leads_created);
          this.set('peopleUpdated', csvImport.get('importSummary').leads_updated);
          this.set(
            'peopleCreatedOrUpdated',
            parseInt(csvImport.get('importSummary').leads_created_or_updated, 10),
          );
        }
        this.set('importComplete', true);
        this.csvController.set('currentStep', 5);
      }
    },

    sync: true,
  }),

  actions: {
    toggleImportSummary() {
      this.toggleProperty('shouldShowImportSummary');
    },
    finish(shouldTransitionToSummary) {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'csv-import-finished',
        object: 'csv-import',
      });

      if (window.opener) {
        window.opener.importSuccessful = true;
        if (shouldTransitionToSummary) {
          window.opener.shouldTransitionToSummary = true;
        }
        window.close();
      }
    },
    openTaggingModal(component, model, options) {
      this.modalService.openModal(component, model, options);
    },
  },
});
