/* RESPONSIBLE TEAM: team-data-foundations */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-controllers */
/* eslint-disable ember/no-observers */
/* eslint-disable ember/no-classic-classes */
import { computed, observer } from '@ember/object';
import { A } from '@ember/array';
import { alias, mapBy } from '@ember/object/computed';
import Evented from '@ember/object/evented';
import Controller, { inject as controller } from '@ember/controller';
import User from 'embercom/models/user';
import PageLoader from 'embercom/controllers/mixins/users-and-companies/page-loader';
import NumberPagination from 'embercom/controllers/mixins/number-pagination';
// eslint-disable-next-line @intercom/intercom/no-legacy-modal
import ModalActions from 'embercom/controllers/mixins/users-and-companies/modal-actions';
import ListSelection from 'embercom/controllers/mixins/users-and-companies/list-selection';
import { findBy } from '@intercom/pulse/lib/computed-properties';
import { inject as service } from '@ember/service';

export default Controller.extend(
  Evented,
  ListSelection,
  NumberPagination,
  PageLoader,
  ModalActions,
  {
    attributeService: service(),
    companyController: controller('apps/app/companies/company'),
    appController: controller('apps/app'),
    company: alias('companyController.model'),
    app: alias('appController.model'),
    isSorting: false,

    init() {
      this._super(...arguments);
      this.set('model', A());
    },

    predicates: computed('company.remote_company_id', function () {
      return [
        {
          attribute: 'company.remote_company_id',
          type: 'string',
          comparison: 'eq',
          value: this.get('company.remote_company_id'),
        },
      ];
    }),

    displayableAttributes: mapBy('attributeService.displayableAttributes', 'attribute'),

    profileDisplayedUserAttributeIdentifiers: [
      'remote_created_at',
      'session_count',
      'last_request_at',
      'last_contacted_at',
      'last_heard_from_at',
    ],

    profileDisplayedUserAttributes: computed('identifiers', function () {
      let self = this;
      return this.profileDisplayedUserAttributeIdentifiers.map(function (identifier) {
        return self
          .get('displayableAttributes')
          .filterBy('identifier', identifier)
          .get('firstObject');
      });
    }),

    selectedSortAttribute: findBy(
      'profileDisplayedUserAttributes',
      'identifier',
      'last_request_at',
    ),

    updateCachedCount(count) {
      this.set('company.user_count', count);
    },

    loadPage(page) {
      let options = {
        page,
        sort_by: this.get('selectedSortAttribute.identifier'),
        sort_direction: 'desc',
      };
      return this.executeSearch(User, 'users', options);
    },

    selectedSortAttributeChanged: observer({
      dependentKeys: ['selectedSortAttribute'],

      fn() {
        this.set('isSorting', true);
        this.loadPage(1);
      },

      sync: true,
    }),

    actions: {
      reload() {
        this.reload();
      },

      reset() {
        this.set('isSelectAllMode', false);
        this.set('isSorting', true);
        this.loadPage(1);
      },
    },
  },
);
