/* RESPONSIBLE TEAM: team-actions */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-controllers */
import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import Range from 'embercom/models/reporting/range';

export default class InstallsAndUninstalls extends Controller {
  @service appService;
  @tracked range = this.getDefaultRange();

  getDefaultRange() {
    let { timezone } = this.appService.app;
    let range = Range.createFromPreset('past_4_weeks', timezone);
    return range;
  }

  @action
  updateRange(range) {
    // We need to clone the range to ensure DidChangeAttributes triggers a change in the FetchReport component
    let { start, end, timezone } = range;
    this.range = new Range(start, end, timezone);
  }
}
