/* RESPONSIBLE TEAM: team-data-interop */

import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import AppStoreController from 'embercom/controllers/base/appstore';

export default class AppStore extends AppStoreController {
  @service declare appstoreService: $TSFixMe;

  declare currentlyLoading: $TSFixMe;

  @action
  searchForApps(query: string) {
    this.setProperties({
      search: query || null,
      category: null,
      installed: null,
      capability: null,
    });
  }

  @action
  clickSeeAllLink(categorySlug: string) {
    this.transitionToRoute(this.appstoreService.baseRoute, {
      queryParams: {
        category: categorySlug,
        search: null,
        installed: null,
        capability: null,
      },
    });
    // @ts-ignore
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'see_all_link',
      category_name: categorySlug,
    });
  }

  @action
  onAppPackageClick(categorySlug: string, appPackage: $TSFixMe) {
    // @ts-ignore
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: appPackage,
      category_name: categorySlug,
    });

    let appPackageCode = appPackage.get('id');
    if (appPackageCode === 'whatsapp-fqam') {
      this.transitionToRoute('apps.app.settings.channels.whatsapp');
    } else {
      this.transitionToRoute({
        queryParams: { app_package_code: appPackageCode },
      });
    }
  }
}
