/* RESPONSIBLE TEAM: team-data-foundations */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-new-mixins */
import { later } from '@ember/runloop';
import { computed } from '@ember/object';
import Mixin from '@ember/object/mixin';
import inflector from 'embercom/lib/inflector';
import numberFormatter from 'embercom/lib/number-formatter';
import { inject as service } from '@ember/service';

export default Mixin.create({
  notificationsService: service(),

  formattedCount: computed('model.count', function () {
    return numberFormatter(this.get('model.count'));
  }),
  headerText: computed('modalTarget', 'model.count', function () {
    if (this.get('model.hasCount')) {
      return inflector(
        `this ${this.modalTarget}`,
        this.get('model.count'),
        `these ${this.formattedCount} ${this.modalTargetPlural}`,
      );
    } else {
      return `selected ${this.modalTargetPlural}`;
    }
  }),

  failureWarningNotification: computed('modalTarget', 'modalAction', function () {
    return `Sorry, due to an error we couldn't ${this.modalAction} your ${this.modalTargetPlural}. Please contact us if this happens again.`;
  }),

  processBulkActionFailure(jqXHR, actionAttribute) {
    let response = jqXHR.jqXHR.responseJSON;
    if (response && response.success === false && response.type === 'countVerificationError') {
      this.notificationsService.notifyError(this.failureWarningNotification);
      this.reset();
      this.send('closeModal');
    } else {
      later(
        this,
        function () {
          this.set('hasErrored', true);
          this.set(actionAttribute, false);
        },
        1000,
      );
    }
  },
});
